import * as React from "react";
import Container from "../components/Container/Container";
import TextLayout from "../layouts/TextLayout";

const RiskDisclaimerPage = () => {
  return (
    <TextLayout
      pageTitle={"Risk Disclaimer | Omnia"}
      headerFixedOnMobile
      hideAdditionalSection
      title={"Risk Disclaimer"}
    >
      <Container textLayout>
        <div className="inner-page-wrap__content">
          <h2>1. Risk disclaimer</h2>

          <p>
            We are very thankful for your interest in the OMNIA Protocol project
            (hereinafter referred to as "Project"), OMNIA Protocol token sale,
            OMNIA tokens (hereinafter referred to as "Tokens") or OMNIA NFTs. However, it
            is a well-known fact that crypto projects are related to various
            risks, which must be taken into account before participating in
            Token sale.
          </p>

          <p>
            Acquisition of the Tokens or NFTs involves a high degree of risk. The
            purchaser should carefully consider the following information about
            these risks before he decides to buy the Tokens/NFTs. If any of the
            following risks occur, Privacy Infrastructure Provider OU
            (hereinafter referred to as “Company”) business, the Project, the
            value of the Tokens/NFTs could be materially adversely affected.
          </p>

          <p>
            The company has described the risks and uncertainties that its
            management believes are material, but these risks and uncertainties
            may not be the only one's Company face. Additional risks and
            uncertainties, including those Company, currently is not aware of or
            deem immaterial, may also materially adversely affect on Company’s
            business, the Project, the value of the Tokens/NFTs. This Risk disclaimer
            applies to all website{" "}
            <a
              href="https://www.omniatech.io/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.omniatech.io/
            </a>
            visitors and Token/NFT purchasers.
          </p>

          <h2>2. Risk connected to the value of tokens/nfts</h2>

          <p>
            <strong>
              No Rights, Functionality, or Features Other than Strictly Provided
              Herein.
            </strong>{" "}
            The Tokens/NFTs do not have any rights, uses, purpose, attributes,
            functionalities, or features, express or implied, including, without
            limitation, any uses, purpose, attributes, functionalities, or
            features on the Project, other than strictly provided in the White
            Paper.
          </p>

          <p>
            <strong>Lack of Development of Market for Tokens/NFTs.</strong> Because
            there has been no prior public trading market for the Tokens/NFTs, the
            sale of the Tokens/NFTs, may not result in an active or liquid market for
            the Tokens/NFTs,, and their price may be highly volatile. Although
            applications have been made to the cryptographic token exchanges for
            the Tokens/NFTs, to be admitted to trading, an active public market may
            not develop or be sustained after the Token sale. If a liquid
            trading market for the Tokens/NFTs, does not develop, the price of the
            Tokens/NFTs, may become more volatile and the Token holder may be unable
            to sell or otherwise transact in the Tokens/NFTs, at any time.
          </p>

          <p>
            <strong>Risks Relating to Highly Speculative Traded Price.</strong>{" "}
            The valuation of digital tokens/nfts in a secondary market is usually not
            transparent, and highly speculative. The Tokens/NFTs do not hold any
            ownership rights to Company’s assets and, therefore, are not backed
            by any tangible asset. Traded price of the Tokens/NFTs can fluctuate
            greatly within a short period of time. There is a high risk that a
            token holder could lose his/her entire contribution amount. In the
            worst-case scenario, the Tokens/NFTs could be rendered worthless.
          </p>

          <p>
            <strong>Tokens/NFTs May Have No Value.</strong> The Tokens/NFTs may have no
            value and there is no guarantee or representation of liquidity for
            the Tokens/NFTs. The Company is not and shall not be responsible for or
            liable for the market value of the Tokens/NFTs, the transferability
            and/or liquidity of the Tokens/NFTs, and/or the availability of any
            market for the Tokens/NFTs through third parties or otherwise.
          </p>

          <p>
            <strong>Tokens/NFTs are Non-Refundable.</strong> Except for the cases
            strictly provided by the applicable legislation or in the legally
            binding documentation on the Tokens/NFTs sale, Company is not obliged to
            provide the Token holders with a refund related to the Tokens/NFTs for
            any reason, and the Token holders will not receive money or other
            compensation in lieu of the refund. No promises of future
            performance or price are or will be made in respect to the Tokens/NFTs,
            including no promise of inherent value, no promise of continuing
            payments, and no guarantee that the Tokens/NFTs will hold any particular
            value. Therefore, the recovery of spent resources may be impossible
            or may be subject to foreign laws or regulations, which may not be
            the same as the private law of the Token holder.
          </p>

          <p>
            <strong>Risks of Negative Publicity.</strong> Negative publicity
            involving the Company, the Project, the Tokens/NFTs may materially and
            adversely affect the market perception or market price of the
            Tokens/NFTs, whether or not it is justified.
          </p>

          <p>
            <strong>
              Use of Tokens/NFTs in Restricted Activities by Third Parties.
            </strong>{" "}
            Programs or websites banned or restricted in certain jurisdictions,
            such as gambling, betting, lottery, sweepstake, pornography, and
            otherwise, could accept different cryptocurrencies or Tokens/NFTs in
            their operation. The regulatory authorities of certain jurisdictions
            could accordingly take administrative or judicial actions against
            such programs or websites or even the developers or users thereof.
            The Company neither intends nor can act as a censor to scrutinize to
            any extent any program or website that uses Tokens/NFTs with such goals.
            Therefore, any punishment, penalty, sanction, crackdown, or other
            regulatory effort made by any governmental authority may more or
            less frighten or deter existing or potential users away from using
            and holding the Tokens/NFTs, and consequently bring material adverse
            impact on the prospect of the Tokens/NFTs.
          </p>

          <p>
            <strong>Risks Arising from Taxation.</strong> The tax
            characterization of the Tokens/NFTs is uncertain. The purchaser shall
            seek his own tax advice in connection with the acquisition, storage,
            transfer, and use of the Tokens/NFTs, which may result in adverse tax
            consequences to the purchaser, including, without limitation,
            withholding taxes, transfer taxes, value-added taxes, income taxes
            and similar taxes, levies, duties or other charges and tax reporting
            requirements.
          </p>

          <h2>3. Blockchain and software risks</h2>

          <p>
            <strong>Blockchain Delay Risk.</strong> On most blockchains used for
            cryptocurrencies' transactions (e.g. Ethereum, Bitcoin, BSC
            blockchains), the timing of block production is determined by proof
            of work so block production can occur at random times. For example,
            the cryptocurrency sent as a payment for the Tokens/NFTs in the final
            seconds of the Token sale may not get included in that period. The
            respective blockchain may not include the purchaser’s transaction at
            the time the purchaser expects and the payment for the Tokens/NFTs may
            reach the intended wallet address, not on the same day the purchaser
            sends the cryptocurrency.
          </p>

          <p>
            <strong>Blockchain Congestion Risk.</strong> Most blockchains used
            for cryptocurrencies' transactions (e.g., Ethereum, Bitcoin, BSC
            blockchains) are prone to periodic congestion during which
            transactions can be delayed or lost. Individuals may also
            intentionally spam the network in an attempt to gain an advantage in
            purchasing cryptographic Tokens/NFTs. That may result in a situation
            where block producers may not include the purchaser’s transaction
            when the purchaser wants or the purchaser’s transaction may not be
            included at all.
          </p>

          <p>
            <strong>Risk of Software Weaknesses.</strong> The token smart
            contract concept, the underlying software application, and software
            of the Project (i.e. the Ethereum, Bitcoin, BSC blockchains) are
            still in an early development stage and unproven. There are no
            representations and warranties that the process for creating the
            Tokens/NFTs will be uninterrupted or error-free. There is an inherent
            risk that the software could contain weaknesses, vulnerabilities, or
            bugs causing, inter alia, the complete loss of the cryptocurrency
            and/or the Tokens/NFTs.
          </p>

          <p>
            <strong>Risk of New Technology.</strong> The Project, the Tokens/NFTs,
            and all of the matters set forth in the White Paper are new and
            untested. The Project and the Tokens/NFTs might not be capable of
            completion, creation, implementation, or adoption. No blockchain
            utilizing the Project may be ever launched. The purchaser of the
            Tokens/NFTs should not rely on the Project, the token smart contract, or
            the ability to receive the Tokens/NFTs associated with the Project in the
            future. Even if the Project is completed, implemented, and adopted,
            it might not function as intended, and any Tokens/NFTs may not have
            functionality that is desirable or valuable. Also, technology is
            changing rapidly, so the Project and the Tokens/NFTs may become outdated.
          </p>

          <h2>4. Security risks</h2>

          <p>
            <strong>Risk of Loss of Private Keys.</strong> The Tokens/NFTs may be
            held by the Token holder in his digital wallet or vault, which
            requires a private key, or a combination of private keys, for
            access. Accordingly, loss of requisite private keys associated with
            such token holder’s digital wallet or vault storing the Tokens/NFTs will
            result in loss of such Tokens/NFTs, access to token holder’s Token
            balance and/or any initial balances in blockchains created by third
            parties. Moreover, any third party that gains access to such private
            keys, including by gaining access to login credentials of a hosted
            wallet or vault service the token holder uses, may be able to
            misappropriate the token holder’s Tokens/NFTs.
          </p>

          <p>
            <strong>Lack of Token Security.</strong> The Tokens/NFTs may be subject
            to expropriation and or/theft. Hackers or other malicious groups or
            organizations may attempt to interfere with the token smart contract
            which creates the Tokens/NFTs or the Tokens/NFTs in a variety of ways,
            including, but not limited to, malware attacks, denial of service
            attacks, consensus-based attacks, Sybil attacks, smurfing and
            spoofing. Furthermore, because the Ethereum Project rests on
            open-source software, there is the risk that Ethereum smart
            contracts may contain intentional or unintentional bugs or
            weaknesses that may negatively affect the Tokens/NFTs or result in the
            loss of Tokens/NFTs, the loss of ability to access or control the Tokens/NFTs.
            In the event of such a software bug or weakness, there may be no
            remedy, and holders of the Tokens/NFTs are not guaranteed any remedy,
            refund, or compensation.
          </p>

          <p>
            <strong>Attacks on Token Smart Contract.</strong> The blockchain
            used for the token smart contract which creates the Tokens/NFTs is
            susceptible to mining attacks, including double-spend attacks,
            majority mining power attacks, "selfish-mining" attacks, and race
            condition attacks. Any successful attacks present a risk to the
            token smart contract, expected proper execution and sequencing of
            the Token transactions, and expected proper execution and sequencing
            of contract computations.
          </p>

          <p>
            <strong>Failure to Map a Public Key to Purchaser’s Account.</strong>{" "}
            Failure of a purchaser of the Tokens/NFTs to map a public key to such
            purchaser's account may result in third parties being unable to
            recognize the purchaser's Token balance on the Ethereum blockchain
            when and if they configure the initial balances of a new
            blockchain-based upon the Project.
          </p>

          <p>
            <strong>Risk of Incompatible Wallet Service.</strong> The wallet or
            wallet service provider used for the acquisition and storage of the
            Tokens/NFTs has to be technically compatible with the Tokens/NFTs. The failure
            to assure this may have the result that the purchaser of the Tokens/NFTs
            will not gain access to his Tokens/NFTs.
          </p>

          <p>
            <strong>
              Risks of Theft of the Funds Raised in the Token Sale.
            </strong>{" "}
            The Company will make every effort to ensure that the funds received
            from the Token Sale will be securely held through the implementation
            of security measures. Notwithstanding such security measures, there
            is no assurance that there will be no theft of the cryptocurrencies
            as a result of hacks, sophisticated cyber-attacks, distributed
            denials of service or errors, vulnerabilities or defects on the
            Website, in the smart contract(s), on the Ethereum or any other
            blockchain, or otherwise. Such events may include, for example,
            flaws in programming or source code leading to exploitation or abuse
            thereof. In such an event, even if the Token Sale is completed, the
            Company may not be able to receive the cryptocurrencies raised and
            to use such funds for the development of the Project and/or for
            launching any future business line. In such a case, the launch of
            the Project might be temporarily or permanently curtailed. As such,
            distributed Tokens/NFTs may hold little worth or value, and this would
            impact its trading price.
          </p>

          <h2>5. Risks relating to company</h2>

          <p>
            <strong>Risks relating to Ineffective Management.</strong> The
            Company may be materially and adversely affected if they fail to
            effectively manage their operations as their business develops and
            evolves, which would have a direct impact on the Company's ability
            to maintain the Project and/or launch any future business lines.
          </p>

          <p>
            <strong>Risks Related to Highly Competitive Environment.</strong>{" "}
            The financial technology and cryptocurrency industries and the
            markets in which the Company competes are highly competitive and
            have grown rapidly over the past years and continue to evolve in
            response to new technological advances, changing business models,
            and other factors. As a result of this constantly changing
            environment, the Company may face operational difficulties in
            adjusting to the changes, and the sustainability of the Company will
            depend on its ability to manage its operations and ensure that it
            hires qualified and competent employees, and provides proper
            training for its personnel. As its business evolves, the Company
            must also expand and adapt its operational infrastructure. The
            Company cannot give any assurance that the Company will be able to
            compete successfully.
          </p>

          <p>
            <strong>
              Risks Relating to General Global Market and Economic Conditions.
            </strong>
            Challenging economic conditions worldwide have from time to time may
            continue to contribute to slowdowns in the information technology
            industry at large. Weakness in the economy could have a negative
            effect on the Company's business, operations, and financial
            condition, including decreases in revenue and operating cash flows,
            and inability to attract future equity and/or debt financing on
            commercially reasonable terms. Additionally, in a down-cycle
            economic environment, the Company may experience the negative
            effects of a slowdown in trading and usage of the Project.
          </p>

          <p>
            <strong>
              Risks of Non-Protection of Intellectual Property Rights.
            </strong>{" "}
            The Company relies on patents and trademarks and unpatented
            proprietary know-how and trade secrets and employs commercially
            reasonable methods, including confidentiality agreements with
            employees and consultants, to protect know-how and trade secrets.
            However, these methods may not afford complete protection and the
            Company cannot give any assurance that third parties will not
            independently develop the know-how and trade secrets or develop
            better production methods than the Company.
          </p>

          <p>
            <strong>Risks of Infringement Claims.</strong> The competitors of
            the Company, other entities, and individuals, may own or claim to
            own intellectual property relating to products and solutions of the
            Company. Third parties may claim that products and solutions and
            underlying technology of the Company infringe or violate their
            intellectual property rights. The Company may be unaware of the
            intellectual property rights that others may claim cover some or all
            of the products or technology of the Company.
          </p>

          <h2>6. Risks relating to project development</h2>

          <p>
            <strong>Risk Related to Reliance on Third Parties.</strong> Even if
            completed, the Project will rely, in whole or partly, on third
            parties to adopt and implement it and to continue to develop,
            supply, and otherwise support it. There is no assurance or guarantee
            that those third parties will complete their work, properly carry
            out their obligations, or otherwise meet anyone's needs, all of
            which might have a material adverse effect on the Project.
          </p>

          <p>
            <strong>Dependence of Project on Senior Management Team.</strong>{" "}
            The ability of the senior management team, which is responsible for
            maintaining the competitive position of the Project, is dependent to
            a large degree on the services of each member of that team. The loss
            or diminution in the services of members of the respective senior
            management team or an inability to attract, retain and maintain
            additional senior management personnel could have a material adverse
            effect on the Project. Competition for personnel with relevant
            expertise is intense due to the small number of qualified
            individuals, and this situation seriously affects the ability to
            retain its existing senior management and attract additional
            qualified senior management personnel, which could have a
            significant adverse impact on the Project.
          </p>

          <p>
            <strong>Dependence of Project on Various Factors.</strong> The
            development of the Project may be abandoned for a number of reasons,
            including lack of interest from the public, lack of funding, lack of
            commercial success or prospects, or departure of key personnel.
          </p>

          <p>
            <strong>Lack of Interest in the Project.</strong> Even if the
            Project is finished and adopted and launched, the ongoing success of
            the Project relies on the interest and participation of third
            parties like developers. There can be no assurance or guarantee that
            there will be sufficient interest or participation in the Project.
          </p>

          <p>
            <strong>Changes to the Project.</strong> The Project is still under
            development and may undergo significant changes over time. Although
            the project management team intends for the Project to have the
            features and specifications set forth in the White Paper, changes to
            such features and specifications can be made for any number of
            reasons, any of which may mean that the Project does not meet
            expectations of the holder of the Tokens/NFTs.
          </p>

          <p>
            <strong>Ability to Introduce New Technologies.</strong> The
            blockchain technologies industry is characterized by rapid
            technological change and the frequent introduction of new products,
            product enhancements, and new distribution methods, each of which
            can decrease demand for current solutions or render them obsolete.
          </p>

          <p>
            <strong>Risk Associated with Other Applications.</strong> The
            Project may give rise to other, alternative projects, promoted by
            unaffiliated third parties, under which the Token will have no
            intrinsic value.
          </p>

          <p>
            <strong>
              Risk of an Unfavorable Fluctuation of Cryptocurrency Value.
            </strong>{" "}
            The proceeds of the sale of the Tokens/NFTs will be denominated in
            cryptocurrency and may be converted into other cryptographic and
            fiat currencies. If the value of cryptocurrencies fluctuates
            unfavorably during or after the Token sale, the project management
            team may not be able to fund development, or may not be able to
            develop or maintain the Project in the manner that it intended.
          </p>

          <p>
            <strong>Risk of Dissolution of Company or Project.</strong> It is
            possible that, due to any number of reasons, including, but not
            limited to, an unfavorable fluctuation in the value of Ethereum,
            Bitcoin, or other cryptographic and fiat currencies, decrease in the
            Tokens/NFTs utility due to negative adoption of the Project, the failure
            of commercial relationships, or intellectual property ownership
            challenges, the Project may no longer be viable to operate and the
            Company may dissolve.
          </p>

          <p>
            <strong>
              Further token sales and development and sale of additional Tokens/NFTs.
            </strong>{" "}
            The Company may, from time to time, and without prior notice or
            consultation, sell additional Tokens/NFTs outside of the token sale.
            Further, the Company may develop or otherwise raise funding for
            Project through any other means it deems necessary. You will not
            necessarily receive notice of the sale of additional Tokens/NFTs or any
            other Tokens/NFTs or fundraising means.
          </p>

          <h2>7. Risks arising in course of company business</h2>

          <p>
            <strong>Risk of Conflicts of Interest.</strong> The Company may be
            engaged in transactions with related parties, including respective
            majority shareholder, companies controlled by him or in which he
            owns an interest, and other affiliates, and may continue to do so in
            the future. Conflicts of interest may arise between any Company
            affiliates and Company, potentially resulting in the conclusion of
            transactions on terms not determined by market forces.
          </p>

          <p>
            <strong>
              Risks Related to Invalidation of Company Transactions.
            </strong>{" "}
            The Company has taken a variety of actions relating to its business
            that, if successfully challenged for not complying with applicable
            legal requirements, could be invalidated or could result in the
            imposition of liabilities on the Company. Since applicable
            legislation may subject to many different interpretations, Company
            may not be able to successfully defend any challenge brought against
            such transactions, and the invalidation of any such transactions or
            imposition of any such liability may, individually or in the
            aggregate, have a material adverse effect on the Project.
          </p>

          <p>
            <strong>Risk Arising from Emerging Markets.</strong> The Company may
            operate in emerging markets. Emerging markets are subject to greater
            risks than more developed markets, including significant legal,
            economic and political risks. Emerging economies are subject to
            rapid change and that the information set out in the White Paper may
            become outdated relatively quickly.
          </p>

          <h2>8. Governmental risks</h2>

          <p>
            <strong>Uncertain Regulatory Framework.</strong> The regulatory
            status of cryptographic tokens/nfts, digital assets, and blockchain
            technology is unclear or unsettled in many jurisdictions. It is
            difficult to predict how or whether governmental authorities will
            regulate such technologies. It is likewise difficult to predict how
            or whether any governmental authority may make changes to existing
            laws, regulations, and/or rules that will affect cryptographic
            tokens/nfts, digital assets, blockchain technology, and its applications.
            Such changes could negatively impact the tokens/nfts in various ways,
            including, for example, through a determination that the tokens/nfts are
            regulated financial instruments that require registration. The
            Company may cease the distribution of the Tokens/NFTs, the development of
            the Project or cease operations in a jurisdiction in the event that
            governmental actions make it unlawful or commercially undesirable to
            continue to do so.
          </p>

          <p>
            <strong>
              Failure to Obtain, Maintain or Renew Licenses and Permits.
            </strong>{" "}
            Although as of the date of starting of the Token sale there are no
            statutory requirements obliging the Company to receive any licenses
            and permits necessary for carrying out its activity, there is the
            risk that such statutory requirements may be adopted in the future
            and may relate to Company. In this case, Company’s business will
            depend on the continuing validity of such licenses and permits and
            its compliance with their terms. Regulatory authorities will
            exercise considerable discretion in the timing of license issuance
            and renewal and the monitoring of licensees’ compliance with license
            terms. Requirements which may be imposed by these authorities and
            which may require Company to comply with numerous standards, recruit
            qualified personnel, maintain necessary technical equipment and
            quality control systems, monitor our operations, maintain
            appropriate filings and, upon request, submit appropriate
            information to the licensing authorities, may be costly and
            time-consuming and may result in delays in the commencement or
            continuation of operation of the Project. Further, private
            individuals and the public at large possess rights to comment on and
            otherwise engage in the licensing process, including through
            intervention in courts and political pressure. Accordingly, the
            licenses Company may need may not be issued or renewed, or if issued
            or renewed, may not be issued or renewed in a timely fashion, or may
            involve requirements that restrict Company's ability to conduct its
            operations or to do so profitably.
          </p>

          <p>
            <strong>Risk of Government Action.</strong> The industry in which
            Company operates is new and may be subject to heightened oversight
            and scrutiny, including investigations or enforcement actions. There
            can be no assurance that governmental authorities will not examine
            the operations of Company’s and/or pursue enforcement actions
            against them. All of this may subject Company to judgments,
            settlements, fines, or penalties, or cause Company to restructure
            its operations and activities or to cease offering certain products
            or services, all of which could harm the Company's reputation or
            lead to higher operational costs, which may, in turn, have a
            material adverse effect on the Tokens/NFTs and/or the development of the
            Project.
          </p>

          <p>
            <strong>
              Risk of Burdensomeness of Applicable Laws, Regulations, and
              Standards.
            </strong>
            Failure to comply with existing laws and regulations or the findings
            of government inspections, or increased governmental regulation of
            the Company’s operations, could result in substantial additional
            compliance costs or various sanctions, which could materially
            adversely affect Company’s business and the Project. The company’s
            operations and properties are subject to regulation by various
            government entities and agencies, in connection with ongoing
            compliance with existing laws, regulations, and standards.
            Regulatory authorities exercise considerable discretion in matters
            of enforcement and interpretation of applicable laws, regulations,
            and standards. Respective authorities have the right to, and
            frequently do, conduct periodic inspections of the Company's
            operations and properties throughout the year. Any such future
            inspections may conclude that Company has violated laws, decrees, or
            regulations, and it may be unable to refute such conclusions or
            remedy the violations. Any Company’s failure to comply with existing
            laws and regulations or the findings of government inspections may
            result in the imposition of fines or penalties or more severe
            sanctions or in requirements that Company ceases certain of its
            business activities, or in criminal and administrative penalties
            applicable to respective officers. Any such decisions, requirements
            or sanctions, or any increase in governmental regulation of
            respective operations, could increase Company's costs and materially
            adversely affect Company’s business and the Project.
          </p>
          <h2>9. Unanticipated risks</h2>
          <p>
            <strong>
              Blockchain technologies and cryptographic tokens such as the
              Tokens/NFTs are relatively new and dynamic technology.
            </strong>{" "}
            In addition to the risks included above, there are other risks
            associated with your purchase, holding, and use of the Tokens/NFTs,
            including those that the Company cannot anticipate. Such risks may
            further appear as unanticipated variations or combinations of the
            risks discussed above.
          </p>
        </div>
      </Container>
    </TextLayout>
  );
};

export default RiskDisclaimerPage;
